import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import defaults from 'lodash/defaults';


import { menuDatesState } from 'app/containers/contexts/menuDates.context';

import { useSelectedFilters } from 'app/connect/connectToSelectedFilters';
import { mapTagsFromUrl, mapTagsToUrl } from './tagsUtils';


export const parseQuery = (queryParams) => {
    const query = queryString.parse(queryParams);
    return mapTagsFromUrl(query);
};

export const setValuesToQuery = (query, filterState, selectedDate) => {
    const {
        tags: queryTags,
        period,
        date,
    } = query;

    const {
        selectedTags,
        selectedPeriod,
        isUserChangesTags,
    } = filterState;

    let tags = [];
    if (selectedTags) {
        /**
            см defaults.js в локальных резолверах
        */
        if (selectedTags.length === 0 && queryTags) {
            if (!isUserChangesTags) {
                tags = queryTags;
            } else {
                tags = selectedTags;
            }
        } else if (selectedTags && selectedTags.length) {
            tags = selectedTags;
        }
    }

    return defaults({
        period: selectedPeriod || period,
        tags,
        date: selectedDate || date,
    }, query);
};

export const stringifyQuery = (query) => {
    const mappedQuery = mapTagsToUrl(query);

    return queryString.stringify(mappedQuery);
};


function QueryUpdater(props) {
    const {
        history: { location: { pathname, search, hash } },
        history,
        Child,
    } = props;

    const { state: { selectedDate } } = useContext(menuDatesState);
    const filter = useSelectedFilters();

    /**
    * Транслирует выбранные параметры фильтра в урл-параметры
    */
    useEffect(() => {
        const urlQuery = parseQuery(search);
        const updatedQuery = setValuesToQuery(urlQuery, filter, selectedDate);
        const querystr = stringifyQuery(updatedQuery);

        if (search !== `?${querystr}` && querystr.length > 0) {
            history.replace(`${pathname}?${querystr}${hash}`);
        }
    }, [history, filter, selectedDate]);

    const urlQuery = parseQuery(search);

    return (
        <Child
            {...props}
            urlQuery={urlQuery}
        />
    );
}

QueryUpdater.propTypes = {
    selectedFilters: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({}).isRequired,
    Child: PropTypes.func.isRequired,
};

const withQueryParams = (Wrapped) => (
    (props) => <QueryUpdater {...props} Child={Wrapped} />
);

export default withQueryParams;
